<nav *ngIf='!hideMenu' class='navbar navbar-expand-xl'>
  <div class='navbar-logo-wrapper'>
    <a class='navbar-brand me-0' routerLink='/'>
      <img *ngIf='environment.environmentType !== EnvironmentType.Production'
           src='assets/img/logo/Nobina_logo_vit.svg' alt='Nobina logo' class='navbar-logo'>
      <img *ngIf='environment.environmentType === EnvironmentType.Production'
           src='assets/img/logo/nobina-logo-2020.svg' alt='Nobina logo'>
    </a>
    <div *ngIf='environment.environmentType !== EnvironmentType.Production'
         class='environment-name'>{{ environment.environmentType }}
    </div>
    <button (click)='toggleCollapsed()' class='navbar-toggler' type='button' data-toggle='collapse'
            data-target='#navbarSupportedContent'
            aria-controls='navbarSupportedContent' aria-expanded='false' aria-label='Toggle navigation'>
      <i class='fa fa-bars text-white'></i>
    </button>
  </div>

  <div [ngClass]="{'collapse': collapsed, 'mb-2': !collapsed, 'navbar-collapse': true}" id='navbarSupportedContent'>
    <ul class='navbar-nav mx-auto' [ngClass]="{'mb-2': !collapsed}">
      <li id='navbarOrders'
          *ngIf='showOrdersMenu'
          class='nav-item'
          [class.active]="path?.startsWith('/order') || path == '/' || path?.startsWith('/unboundorder')">
        <a class='nav-link' routerLink='orders' i18n='@@app.orders' (click)='collapseMenu()'>Beställningar</a>
      </li>
      <li id='navbarHistory'
          *ngIf='showHistoryMenu'
          class='nav-item' [class.active]="path?.startsWith('/history')">
        <a class='nav-link' routerLink='history' i18n='@@app.history' (click)='collapseMenu()'>Historik</a>
      </li>
      <li id='navbarHome' *ngIf='showHomeMenu' class='nav-item'
          [class.active]="path?.indexOf('/subcontractor-orders') > 0">
        <a class='nav-link' routerLink='subcontractor/{{selectedSubcontatorId}}/subcontractor-orders'
           i18n='@@app.home' (click)='collapseMenu()'>Hem</a>
      </li>
      <li id='navbarSubcontractorHistory' *ngIf='showSubcontractorHistoryMenu' class='nav-item'
          [class.active]="(path?.indexOf('/history') > 0 || path?.indexOf('/assignment-history') > 0)">
        <a class='nav-link' routerLink='subcontractor/{{selectedSubcontatorId}}/history' i18n='@@app.history'
           (click)='collapseMenu()'>Historik</a>
      </li>
      <li id='navbarFollowUp'
          *ngIf='showOutcomesMenu'
          class='nav-item'
          [class.active]="path?.startsWith('/billing')">
        <a class='nav-link' routerLink='billing/outcomes' i18n='@@app.follow-up' (click)='collapseMenu()'>Uppföljning</a>
      </li>
      <li id='navbarOpertatorFollowUp' *ngIf='showOperatorOutcomesMenu'
          class='nav-item'
          [class.active]="path?.startsWith('/billing')">
        <a class='nav-link' routerLink='billing/reports/create-report' i18n='@@app.follow-up'
           (click)='collapseMenu()'>Uppföljning</a>
      </li>
      <li id='navbarPlanning'
          *ngIf='showPlanningMenu'
          class='nav-item'>
        <a class='nav-link' i18n='@@app.planning' [href]='planningLink'>Planering</a>
      </li>
      <li id='navbarAdministration' *ngIf='showAdministrateMenu' class='nav-item'
          [class.active]="path?.startsWith('/settings')">
        <a class='nav-link' routerLink='settings/import-order' i18n='@@app.administration' (click)='collapseMenu()'>Administration</a>
      </li>
    </ul>
    <app-search class='me-auto'></app-search>
  </div>
  <ul class='navbar-nav' [ngClass]="{'ms-auto': collapsed, 'me-auto': !collapsed}">
    <li class='nav-item' *ngIf='showSearchMenu' >
        <a id='navbarSearch' class='nav-link' href='/order-new/search'>
          <img class='menu-icon' src='assets/img/icons/search-white.png' alt='Search icon'/>
        </a>
    </li>
    <li class='nav-item'>
          <span class='notification-wrapper'>
            <div (click)='openNotifications(); collapseMenu()' id='nav-link-padding' class='nav-link'>
              <span *ngIf='numberOfUnreadNotifications' class='red-dot'>{{ numberOfUnreadNotifications }}</span>
              <img class='menu-icon' src='assets/img/icons/notification.png' alt='Notification icon' />
            </div>
          </span>
    </li>
  </ul>
  <div ngbDropdown>
    <a id='nav-link-padding' class='nav-link d-flex align-items-center' ngbDropdownToggle>
      <img class='user-icon' src='assets/img/icons/account.png' alt='Account icon' />
      <p class='m-0 ms-1 d-none d-sm-block'>{{ displayedUserName }}</p>
    </a>
    <div ngbDropdownMenu class='dropdown-menu-end dropdown-menu-xxxxl-start'>
      <div class='dropdown-body'>
        <div class='px-3 pb-3'>
          <div *ngIf='userEmail'>{{ userEmail }}</div>
          <br *ngIf='userEmail' />
          <div class="dropdown-header" *ngIf="isSingleRole" i18n="@@app.my-role">Min roll:</div>
          <div class="dropdown-header" *ngIf="!isSingleRole" i18n="@@app.my-roles">Mina roller:</div>
          <div>{{ myUserRoles }}</div>
          <br />
          <div class="dropdown-header" i18n='@@app.my-organisation'>Mina organisationer:</div>
          <div>{{ myOrganisation }}</div>
        </div>

        <a *ngIf='showManual' href='https://ersatta.nobina.se/manual/trip/#/' target="_blank" class='dropdown-item p-3'>
          <img class='menu-icon' src='assets/img/icons/manual.png' alt='User manual' />
          <ng-container i18n='@@app.user-manual'>Manual</ng-container>
        </a>

        <a *ngIf='userOperatorId$ | async as operatorId' href='/garage/operator/{{operatorId}}/users' class='dropdown-item p-3'>
          <img class='menu-icon' src='assets/img/icons/user-cog.png' alt='User management' />
          <ng-container i18n='@@app.manage-operator-users'>Hantera mina användare</ng-container>
        </a>

        <a *ngIf='userGarageId$ | async as garageId' href='/garage/{{garageId}}/users' class='dropdown-item p-3'>
          <img class='menu-icon' src='assets/img/icons/bus.png' alt='Garage icon' />
          <ng-container i18n='@@app.manage-garage'>Hantera mitt garage</ng-container>
        </a>
        <a class='dropdown-item p-3' (click)='navigateToSettings()'
           *ngIf='showOperatorMenu'>
          <img class='menu-icon' src='assets/img/icons/user-cog.png' alt='User settings icon' />
          <ng-container i18n='@@app.select-operators'>Välj operatörer</ng-container>
        </a>
        <a id='logout' class='dropdown-item p-3' (click)='logout()'>
          <img class='menu-icon' src='assets/img/icons/log-out.png' alt='Logout icon' />
          <ng-container i18n='@@app.log-out'>Logga ut</ng-container>
        </a>
      </div>
    </div>
  </div>
</nav>
