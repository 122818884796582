import {Component, OnInit} from '@angular/core';
import {EnvironmentType} from '@models/environment.model';
import {environment} from '../../../../environments/environment';
import {UserRoles} from '@authentication/user-roles';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {NotificationsModalComponent} from '@common/components/notifications-modal/notifications-modal.component';
import {AuthService} from '@services/auth.service';
import {OperatorService} from '@services/operator.service';
import {ActivatedRoute, ActivationEnd, Router} from '@angular/router';
import {AdminService} from '@services/admin.service';
import {map, take} from 'rxjs/operators';
import {filter, Observable, shareReplay} from 'rxjs';
import {Garages} from '@models/garages';
import {GarageService} from '@services/garage.service';
import {NotificationService} from '@services/notification.service';
import {Operator} from "@models/operator";

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit {
  protected readonly EnvironmentType = EnvironmentType;
  protected readonly environment = environment;
  protected readonly UserRoles = UserRoles;

  constructor(private modalService: NgbModal,
              private authService: AuthService,
              private operatorService: OperatorService,
              private router: Router,
              private route: ActivatedRoute,
              private adminService: AdminService,
              private garageService: GarageService,
              private notificationService: NotificationService) {

    this.route.queryParams.subscribe(params => {
      if (params['hideMenu']) {
        sessionStorage.setItem('hideMenu', params['hideMenu']);
      }
      this.hideMenu = sessionStorage.getItem('hideMenu') === 'true';
    });

    this.router.events.subscribe((val) => {
      if (val instanceof ActivationEnd) {
        if (val.snapshot.url) {
          this.path = '/' + val.snapshot.url.join('/');
        }
        if (this.mustSetOperators) {
          this.navigateToSettings();
        }
      }
    });
  }

  userGarageId$: Observable<number>;
  userOperatorId$: Observable<number>;

  collapsed = true;
  myUserRoles = '';
  isSingleRole = false;
  userEmail = null;
  showOrdersMenu = false;
  showHistoryMenu = false;
  showHomeMenu = false;
  showSubcontractorHistoryMenu = false;
  showOutcomesMenu = false;
  showOperatorOutcomesMenu = false;
  showPlanningMenu = false;
  showManual = false;
  planningLink = '';
  showAdministrateMenu = false;
  showSearchMenu = false;
  displayedUserName = '';
  selectedSubcontatorId = '';
  mustSetOperators = false;
  showOperatorMenu = false;
  myOrganisation = '';
  hideMenu = false;
  numberOfUnreadNotifications = 0;
  path: string;

  ngOnInit() {
    this.init();
  }

  toggleCollapsed(): void {
    this.collapsed = !this.collapsed;
  }

  collapseMenu(): void {
    this.collapsed = true;
  }

  openNotifications() {
    const opts: NgbModalOptions = { windowClass: 'notificationModal', backdropClass: 'notificationModalBackdrop' };
    this.modalService.open(NotificationsModalComponent, opts);
  }

  private _initObservables() {
    if (this.authService.userHasAnyRole([UserRoles.UETrafikledare])) {
      this.userGarageId$ = this.garageService.getGarageState().pipe(
        filter((garages: Garages) => garages.data.length > 0),
        map((garages: Garages) => garages.data[0].id),
        shareReplay(1),
      );
    }

    if(this.authService.userHasAnyRole([UserRoles.OperatorTrafikledare])){
      this.userOperatorId$ = this.operatorService.getOperators(true, false).pipe(
        map(response => response.body),
        filter((operators: Operator[]) => operators.length > 0),
        filter(() => environment.enableOperatorUserManagement),
        map((operators: Operator[]) => operators[0].operatorOrganisationId),
        shareReplay(1),
      )
    }
  }

  init() {
    this.authService.userLoadededEvent$.subscribe((user) => {
      if (!user?.idTokenClaims?.role) {
        return '-';
      }
      const tmp = user.idTokenClaims.role.toString().split(',');
      this.myUserRoles = tmp.map(element => UserRoles[+element]).filter(x => x !== undefined).join(', ');
      this.isSingleRole = this.myUserRoles.split(',').length === 1;
      this.userEmail = user.idTokenClaims.emails ? user.idTokenClaims.emails[0] : '';
      this.showOrdersMenu = this.showHistoryMenu = this.authService.userHasAnyRole([UserRoles.NobinaTrafikledare, UserRoles.NobinaLeveransansvarig, UserRoles.BergkvaraTrafikledare, UserRoles.OperatorTrafikledare]);
      this.showHomeMenu = this.showSubcontractorHistoryMenu = this.authService.userHasAnyRole([UserRoles.UETrafikledare]);
      this.showOutcomesMenu = this.authService.userHasAnyRole([UserRoles.NobinaLeveransansvarig, UserRoles.BergkvaraTrafikledare, UserRoles.UETrafikledare]) && environment.enableBilling;
      this.showOperatorOutcomesMenu = this.authService.userHasAnyRole([UserRoles.OperatorTrafikledare]) && environment.enableBilling;
      this.showPlanningMenu = this.authService.userHasAnyRole([UserRoles.NobinaTrafikledare, UserRoles.NobinaLeveransansvarig, UserRoles.BergkvaraTrafikledare, UserRoles.OperatorTrafikledare, UserRoles.UETrafikledare]) && (environment.enablePlanningPage || environment.enableCalendarPage);
      this.planningLink = environment.enablePlanningPage ? 'order-new/planning' : 'order-new/calendar';
      this.showAdministrateMenu = this.authService.userHasAnyRole([UserRoles.NobinaLeveransansvarig]);
      this.showSearchMenu = environment.enableSearchPage;
      this.showOperatorMenu = this.authService.userHasAnyRole([UserRoles.NobinaTrafikledare, UserRoles.NobinaLeveransansvarig, UserRoles.OperatorTrafikledare]);
      this.showManual = this.authService.userHasAnyRole([UserRoles.NobinaLeveransansvarig, UserRoles.NobinaTrafikledare]);
      this.selectedSubcontatorId = user.idTokenClaims.garageIds[0] as string;
      if (user.idTokenClaims.given_name !== null && user.idTokenClaims.given_name !== undefined
        && user.idTokenClaims.family_name !== null && user.idTokenClaims.family_name !== undefined) {
        this.displayedUserName = user.idTokenClaims.given_name + ' ' + user.idTokenClaims.family_name;
      } else if (user.idTokenClaims.name) {
        this.displayedUserName = user.idTokenClaims.name;
      } else {
        this.displayedUserName = user.idTokenClaims.email as string;
      }

      this.adminService.getMyOrganization().pipe(take(1)).subscribe(x => this.myOrganisation = x.body.organization);

      if (this.authService.userHasAnyRole([UserRoles.NobinaTrafikledare])) {
        this.operatorService.getOperators(true).pipe(take(1))
          .subscribe(ops => {
            if (!ops.body.length) {
              this.mustSetOperators = true;
              this.navigateToSettings();
            }
          });
      }

      this.notificationService.getUnreadNotificationsCount().subscribe(count => {
        this.numberOfUnreadNotifications = count;
      });

      this._initObservables();
    });
  }


  navigateToSettings() {
    this.router.navigate(['usersettings']);
  }

  logout() {
    this.authService.logout();
  }
}
