<div>
  <div *ngFor="let operator of operators; let i=index" class="row">
    <label class="col py-2">
      <input
        [id]="'filter-subcontractors-' + i"
        type="checkbox"
        [checked]="selectedOperatorsIds.indexOf(operator.id) > -1"
        (change)="toggleSelectedOperator(operator)"
      >
      {{operator.name}}
      <a *ngIf="showOperatorLinks"
        [href]="'garage/operator/' + operator.operatorOrganisationId + '/users'"
        class="operator-user-link"
      >
      <div class="user-icon-tooltip-container">
        <img src="/assets/img/icons/user.png" alt="User icon" class="user-icon"/>
        <span class="user-icon-tooltip" i18n="@@user-icon-tooltip">Användare</span>
      </div>
      </a>
    </label>
  </div>
</div>
